<!--申请结算（机构端）-->
<template>
  <div class="courseUser">
    <div class="pageContol listWrap templateList">
      <div class="framePage">
        <div class="framePage-title">
          <span>
            <em>当前位置：</em>
            <a href="javascript:;">结算管理</a>
            <i>></i>
            <a href="javascript:;" @click="init" class="cur-a">结算申请</a>
          </span>
        </div>
        <div class="framePage-body">
          <div
            class="operationControl"
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <div class="searchbox" style="margin-bottom: 15px">
              <div title="班级编码" class="searchboxItem ci-full">
                <span class="itemLabel">班级编码:</span>
                <el-input
                  v-model="selectData.projectCode"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入班级编码"
                />
              </div>
              <div title="班级名称" class="searchboxItem ci-full">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="selectData.projectName"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入班级名称"
                />
              </div>
              <div title="行政区划" class="searchboxItem ci-full">
                <span class="itemLabel">行政区划:</span>
                <el-cascader
                  v-model="selectData.areaId"
                  :options="areatreeList"
                  :props="propsarea"
                  :disabled="disabled"
                  clearable
                  filterable
                  size="small"
                ></el-cascader>
              </div>
            </div>
            <div class="searchbox">
              <div title="培训类型" class="searchboxItem ci-full">
                <span class="itemLabel">培训类型:</span>
                <el-cascader
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  v-model="selectData.trainTypeId"
                  size="small"
                  clearable
                  :options="trainTypeList"
                  @change="handleTrainType"
                ></el-cascader>
              </div>
              <div title="班级状态:" class="searchboxItem ci-full">
                <span class="itemLabel">班级状态:</span>
                <el-select
                  size="small"
                  v-model="selectData.projectState"
                  placeholder="请选择班级状态"
                  clearable
                >
                  <el-option
                    v-for="item in projectStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="batchSettleAccounts"
                  >申请结算</el-button
                >
              </div>
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                :row-key="(row) => row.projectId"
                @selection-change="handleSelectionChange"
                stripe
              >
                <el-table-column
                  type="selection"
                  width="55"
                  :reserve-selection="true"
                  fixed
                  align="center"
                  label="多选"
                >
                </el-table-column>
                <el-table-column
                  label="班级编码"
                  align="left"
                  prop="projectCode"
                  show-overflow-tooltip
                  width="240"
                  fixed
                />
                <el-table-column
                  label="班级名称"
                  align="left"
                  show-overflow-tooltip
                  prop="projectName"
                  min-width="240"
                />
                <el-table-column
                  label="所属机构"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
                  min-width="200"
                />
                <el-table-column
                  label="行政区划"
                  align="left"
                  show-overflow-tooltip
                  prop="areaNamePath"
                  min-width="200"
                />

                <el-table-column
                  label="培训类型"
                  align="left"
                  show-overflow-tooltip
                  prop="trainTypeNamePath"
                  min-width="150"
                />
                <el-table-column
                  label="班级状态"
                  align="left"
                  show-overflow-tooltip
                  prop="projectState"
                >
                  <template slot-scope="{ row }">
                    {{ $setDictionary("PROJECTSTATE", row.projectState) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="注册人数"
                  align="right"
                  show-overflow-tooltip
                  prop="registerNum"
                />
                <el-table-column
                  label="结业人数"
                  align="right"
                  show-overflow-tooltip
                  prop="graduateNum"
                />
                <el-table-column
                  label="总课时"
                  align="right"
                  show-overflow-tooltip
                  prop="totalLessonNum"
                />
                <el-table-column
                  label="公共课时"
                  align="right"
                  show-overflow-tooltip
                  prop="publicLessonNum"
                />
                <el-table-column
                  label="操作"
                  width="150px"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="{ row }"  class="flexcc">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="settleAccounts(row)"
                      >申请结算</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
          <div class="myBatch">
            <div class="BatchBox">
              <div class="BatchBoxFirst">
                <p>已选班级</p>
                <p>（{{ multipleSelection.length }}）</p>
              </div>
              <div class="BatchBoxSecond ovy-a">
                <div class="BatchforBox">
                  <div
                    v-for="(item, index) in multipleSelection"
                    :key="item.projectId"
                    class="BatchforFlex"
                  >
                    <div class="BatchImgBox" @click="deleteImg(item)">
                      <img
                        :src="require('@/assets/close_.png')"
                        alt=""
                        @mouseover="transToWhite(index, $event)"
                        @mouseleave="transToBlack(index, $event)"
                      />
                    </div>
                    <span style="padding-left: 5px">{{
                      item.projectName
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "applySettlementInstitution",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      selectData: {
        //搜索object
        areaId: "", //行政区划
        trainTypeId: "", //培训类型
        projectName: "", //班级名称
        projectCode: "", //班级编码
        compId: "", //所属机构
        projectState: "", //班级状态
      },
      areatreeList: [], //行政区划list
      trainTypeList: [], //培训类型list
      CompanyList: [], // 所属机构list
      projectStateList: [], //班级状态list
      propsarea: {
        //行政区划prop
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      multipleSelection: [], //列表选中list
    };
  },
  created() {
    this.init();
  },
  methods: {
    /* 鼠标移入 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    /* 鼠标移出 */
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    deleteImg(itemobj) {
      const SELECTION = this.$refs.multipleTable.selection
      this.$refs.multipleTable.toggleRowSelection(SELECTION.find(e=>e.projectId === itemobj.projectId),false)
    },
    init() {
      this.getTableHeight(); //获取列表高度
      this.getareatree(); //获取行政区划
      this.getTraintype(); //获取培训类型
      this.getclassstatusList(); //班级状态list
      this.getData(); //获取列表
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    //班级状态list
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.projectStateList = list.filter(
        (e) => e.value != "10" && e.value != "20" && e.value != "40"
      );
    },
    //获取列表
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.selectData,
      };
      this.doFetch({
        url: "/biz/new/bill/comp/pageList",
        params,
        pageNum,
      });
    },
    // 多选change
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // 单个申请结算
    settleAccounts(row) {
      this.getSettleAccounts([row]);
    },
    // 批量申请结算
    batchSettleAccounts() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请至少勾选一项！");
        return;
      }
      this.getSettleAccounts(this.multipleSelection);
    },
    // 申请结算接口
    getSettleAccounts(settlementArray) {
      let newSettlementArray = [];
      // 处理结算人数特殊字段
      newSettlementArray = settlementArray.map((e) => {
        return {
          ...e,
          settleNum: e.graduateNum,
        };
      });
      let data = {
        compId: newSettlementArray[0].compId,
        compName: newSettlementArray[0].compName,
        projectList: newSettlementArray,
      };
      this.$post("/biz/new/bill/comp/applySettlement", data)
        .then((res) => {
          if (res.status == 0) {
            this.$message.success("申请结算成功！可在【结算单管理】模块查看审核进度！");
            this.clearSelection();
            this.getData(1);
          }
        })
        .catch(() => {
          return;
        });
    },
    // 清空多选
    clearSelection() {
      this.multipleSelection = [];
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5 + 3) * 16;
      if (opDom) {
        tHeight -=  0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //两个字搜索 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  mounted: function () {},
};
</script>

<style lang="less" scope>
.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
.myBatch {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>
